import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Analytics } from '@vercel/analytics/react';
import axios from 'axios';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './App.css';

import G from './G';


import { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper';

const regionNames = new Intl.DisplayNames(['en'], {type: 'region'});


function App() {
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [city, setCity] = useState('');
  const [fetchAttempted, setFetchAttempted] = useState(false);
  const currentDate = new Date();
  const fiveDaysBefore = new Date(currentDate.getTime() - 4 * 24 * 60 * 60 * 1000);
  const oneDayAfter = new Date(currentDate.getTime() + 2 * 24 * 60 * 60 * 1000);
  const [timeLeft, setTimeLeft] = useState(10 * 60);

  const formatDateString = (date) => {
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
  };



  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
        document.body.style.overflow = "scroll"
    };
}, []);

useEffect(() => {
  setFetchAttempted(true); // Set to true as fetch begins

  fetch('/api/location')
    .then(response => response.json())
    .then(data => {
      const decodedCity = decodeURIComponent(data.city.replace(/\+/g, ' '));

      if (decodedCity === "unknown") {
        // If city is "unknown", fetch from the external API
        fetch('https://freeipapi.com/api/json/')
          .then(response => response.json())
          .then(externalData => {
            const externalDecodedCity = decodeURIComponent(externalData.cityName.replace(/\+/g, ' '));
            setCountry(externalData.countryCode);
            setCity(externalDecodedCity);
            console.log(externalData); // Log the external API data
          })
          .catch(error => {
            console.error('Fetch error on external API:', error);
          });
      } else {
        // Use the local API data
        setCountry(data.country);
        setRegion(regionNames.of(data.country.toUpperCase())); // Assumes regionNames is defined and imported
        setCity(decodedCity);
        console.log(data); // Log the local API data
      }
    })
    .catch(error => {
      console.error('Fetch error on local API:', error);
      // Optionally handle the error by fetching from external API or other means
    });

}, []);

  useEffect(() => {
    // Update the countdown timer every second
    const timer = setTimeout(() => {
      setTimeLeft((prevTimeLeft) => (prevTimeLeft > 0 ? prevTimeLeft - 1 : 0));
    }, 1000);

    // Clear the timer when component unmounts or timeLeft changes
    return () => clearTimeout(timer);
  }, [timeLeft]);

  // Function to format the remaining time as "0h 0m 0s"
  const formatTimeLeft = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}m ${seconds}s`;
  };


  return (
    <Router>
    <Routes>
      <Route path="/" element={
    <div className="container">


    <a class="header-logo" href="https://onlyfans.com/rimgailess/c7">
     <div class="onlyfans"></div>
    </a>
      <img 
        src="https://i.ibb.co/1KD8r0V/photo-2024-06-04-19-35-38-modified.png"  
        alt="Header Image"
        className="header-image"
      />

<div className='textContainer'>
        <section>
            <div className='username'><strong>Ri💋</strong></div>
            <div className='useraccountname'>@rimgailess <br/></div>
            <div className='useraccountname'> <br/></div>



        </section>
      </div>

      <div className="detailsText">
            <strong>📍 {city}, {country} {country && <img 
                src={`https://flagcdn.com/w20/${country.toLowerCase()}.png`} 
                alt={`${country} Flag`}
                style={{ width: '20px', height: '15px', verticalAlign: 'middle' }}
            />} ❤️29 years old ♓️ Pisces<br/></strong>
            🟢 Online Now<br/><br/>

            Get exclusive access to my content and interact with me directly. <br/><br/>

            🍑 💦 Do you live near<strong> {city}</strong>?📍 DM me about making content 😉 🍑
        </div>




      <div class="textContainer3">
      <a href="https://onlyfans.com/rimgailess/c7" id="customButton">
      <div class="onlyfans"></div>
       My Onlyfans page 🔞
      </a>
      </div>






    
      <Analytics/>
    </div>
      } />
      <Route path="I-Am-Waiting-For-You/onlyfans.com/rimgailess" element={<G />} />
      </Routes>
      </Router>





  );
}

export default App;
